import takbox from "./takboxRosaGallery.jpg";
import montBlanc from "./montBlanc.jpg";
import trangiaNatur from "./trangiaNaturGallery.jpg";
import stolen from "./stolenGallery.png";
import container from "./containerGallery.png";
import timeline from "./timelineGallery.png";
import plant from "./plantWateringGallery.jpg";
import TEG from "./TEGGallery.jpg";
import CANDELA from "./KTH_8.jpg";
export const photos = [
  {
    src: CANDELA,
    width: 4416,
    height: 2947,
  },
  {
    src: TEG,
    width: 4416,
    height: 2947,
  },
  {
    src: plant,
    width: 900,
    height: 675,
  },
  {
    src: timeline,
    width: 1920,
    height: 944,
  },
  {
    src: container,
    width: 662,
    height: 732,
  },
  {
    src: takbox,
    width: 1280,
    height: 946,
  },
  // {
  //   src: montBlanc,
  //   width: 603,
  //   height: 603,
  // },
  {
    src: trangiaNatur,
    width: 1334,
    height: 890,
  },
  {
    src: stolen,
    width: 774,
    height: 760,
  },

  // {
  //   src: "https://source.unsplash.com/u9cG4cuJ6bU/4927x1000",
  //   width: 4927,
  //   height: 1000,
  // },
  // {
  //   src: "https://source.unsplash.com/epcsn8Ed8kY/600x799",
  //   width: 3,
  //   height: 4,
  // },
  // {
  //   src: "https://source.unsplash.com/NQSWvyVRIJk/800x599",
  //   width: 4,
  //   height: 3,
  // },
  // {
  //   src: "https://source.unsplash.com/zh7GEuORbUw/600x799",
  //   width: 3,
  //   height: 4,
  // },
  // {
  //   src: "https://source.unsplash.com/PpOHJezOalU/800x599",
  //   width: 4,
  //   height: 3,
  // },
  // {
  //   src: "https://source.unsplash.com/I1ASdgphUH4/800x599",
  //   width: 4,
  //   height: 3,
  // },
  // {
  //   src: "https://source.unsplash.com/XiDA78wAZVw/600x799",
  //   width: 3,
  //   height: 4,
  // },
  // {
  //   src: "https://source.unsplash.com/x8xJpClTvR0/800x599",
  //   width: 4,
  //   height: 3,
  // },

  // {
  //   src: "https://source.unsplash.com/qGQNmBE7mYw/800x599",
  //   width: 4,
  //   height: 3,
  // },
  // {
  //   src: "https://source.unsplash.com/NuO6iTBkHxE/800x599",
  //   width: 4,
  //   height: 3,
  // },
  // {
  //   src: "https://source.unsplash.com/pF1ug8ysTtY/600x400",
  //   width: 4,
  //   height: 3,
  // },
  // {
  //   src: "https://source.unsplash.com/A-fubu9QJxE/800x533",
  //   width: 4,
  //   height: 3,
  // },
  // {
  //   src: "https://source.unsplash.com/5P91SF0zNsI/740x494",
  //   width: 4,
  //   height: 3,
  // },
];
