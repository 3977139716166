import Navbar from "../COMPONENTS/navbar/navbar";
import LogoCorner from "../COMPONENTS/logoCorners/logoCorner"
export const LandingPageView = ({}) => {
  return (
   
      <div className="landingPage">
      <LogoCorner onClick={()=>{}}text="SG"/>
    
      {<Navbar />}
    </div>
  );
};
