import React from "react";
import { LandingPageView } from "../VIEW/LandingPageView";

function LandingPage() {
  // const logo = document.querySelectorAll("#homeButtonLand path");
  // for (let i = 0; i < logo.length; i++) {
  //   console.log(`Letter ${i} is ${logo[i].getTotalLength()}`);
  // }
  return <LandingPageView />;
}

export default LandingPage;
